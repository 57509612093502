<template>
	<div id="ag-grid-demo">
		<vx-card class="mb-base">
			<div slot="no-body" class="p-6">
				<ag-grid-vue
					:gridOptions="gridOptions"
					class="ag-theme-material w-100 mb-4 ag-grid-table ag-grid-table--small"
					:columnDefs="columnDefs"
					:defaultColDef="defaultColDef"
					:rowData="tableData"
					rowSelection="multiple"
					:enableRangeSelection="true"
					:suppressCopyRowsToClipboard="true"
					colResizeDefault="shift"
					:suppressDragLeaveHidesColumns="true"
					:animateRows="false"
					:floatingFilter="true"
					:pagination="true"
					:paginationPageSize="paginationPageSize"
					:suppressPaginationPanel="true"
					:suppressRowClickSelection="true"
					:rowClassRules="rowClassRules"
					:statusBar="statusBar"
					:sideBar="sideBar"
					:rowHeight="38"
					:getContextMenuItems="getContextMenuItems"
					@row-selected="onRowSelected"
					@column-resized="onColumnResized">
				</ag-grid-vue>
				<div class="vx-row">
					<div class="vx-col w-full">
						<vs-pagination
						:total="totalPages"
						:max="maxPageNumbers"
						v-model="currentPage" />
					</div>
				</div>
			</div>
		</vx-card>
	</div>
</template>

<script>
	import { AgGridVue } from "ag-grid-vue"
	import "ag-grid-enterprise";

	import '@/assets/scss/vuesax/extraComponents/agGridStyleOverride.scss'

	export default {
		components: {
			AgGridVue,
		},
        props: {
            tableData: {
                type: Array
			},
			userRole: {
				type: String,
				default: null
			},
		},
		data() {
			return {
				searchQuery: '',
				gridOptions: {},
				maxPageNumbers: 7,
				gridApi: null,
				defaultColDef: {
					sortable: true,
					resizable: true,
					suppressMenu: true,
					suppressMovable: false,
					filter: false,
					filterParams: {
						suppressAndOrCondition: true,
					},
				},
				rowClassRules: null,
				statusBar: null,
				sideBar: null,
				columnDefs: [
				{
					headerName: 'Domain',
					field: 'domain',
					minWidth: 275,
					width: 275,
					filter: "agTextColumnFilter",
					pinned: 'left',
				},
				{
					headerName: 'Input Domain',
					field: 'input_domain',
					filter: "agTextColumnFilter",
					minWidth: 275,
					hide: true
				},
				{
					headerName: 'Keyword',
					field: 'keyword',
					filter: "agTextColumnFilter",
					minWidth: 275,
					hide: true
				},
				{
					headerName: 'Fuzzer',
					field: 'fuzzer',
					filter: "agTextColumnFilter",
					minWidth: 150
				},
				{
					headerName: 'IP',
					field: 'ip',
					filter: "agTextColumnFilter",
					minWidth: 200,
					cellRenderer: (data) => {
						if(data.value == '0') {
							return 'None resolved'
						}
						else {
							return '';
						}
					},
				},
				{
					headerName: 'Parked',
					field: 'parked',
					filter: "agTextColumnFilter",
					minWidth: 150
				},
				{
					headerName: 'Parked Score',
					field: 'parked_score',
					filter: "agTextColumnFilter",
					minWidth: 150
				},
				{
					headerName: 'Created At',
					field: 'created_at',
					filter: 'agMultiColumnFilter',
					filterParams: {
						filters: [
							{
								filter: 'agTextColumnFilter',
								filterParams: {
									suppressAndOrCondition: true,
									newRowsAction: 'keep',
								}
							},
							{
								filter: 'agDateColumnFilter',
								filterParams: {
									suppressAndOrCondition: true,
									defaultOption: 'inRange',
									newRowsAction: 'keep',
									comparator: function(filterLocalDateAtMidnight, cellValue) {
										if (cellValue === null) return -1;

										let cellDate = new Date(cellValue);
										
										let cellDateString = new Date(cellDate.toDateString());
										let filterLocalDateAtMidnightString = new Date(filterLocalDateAtMidnight.toDateString());
										
										if (cellDateString.getTime() === filterLocalDateAtMidnightString.getTime()) {
											return 0;
										}
										if (cellDate < filterLocalDateAtMidnight) {
											return -1;
										}
										if (cellDate > filterLocalDateAtMidnight) {
											return 1;
										}
									},
								}
							},
						],
					},
					minWidth: 235,
					cellRenderer: (data) => {
						if(data.value) {
							return this.$moment.utc(data.value).format('DD-MM-YYYY HH:mm:ss')
						}
						else {
							return ''
						}
					},
				},
				{
					headerName: 'Updated At',
					field: 'updated_at',
					filter: 'agMultiColumnFilter',
					filterParams: {
						filters: [
							{
								filter: 'agTextColumnFilter',
								filterParams: {
									suppressAndOrCondition: true,
									newRowsAction: 'keep',
								}
							},
							{
								filter: 'agDateColumnFilter',
								filterParams: {
									suppressAndOrCondition: true,
									defaultOption: 'inRange',
									newRowsAction: 'keep',
									comparator: function(filterLocalDateAtMidnight, cellValue) {
										if (cellValue === null) return -1;

										let cellDate = new Date(cellValue);
										
										let cellDateString = new Date(cellDate.toDateString());
										let filterLocalDateAtMidnightString = new Date(filterLocalDateAtMidnight.toDateString());
										
										if (cellDateString.getTime() === filterLocalDateAtMidnightString.getTime()) {
											return 0;
										}
										if (cellDate < filterLocalDateAtMidnight) {
											return -1;
										}
										if (cellDate > filterLocalDateAtMidnight) {
											return 1;
										}
									},
								}
							},
						],
					},
					minWidth: 235,
					hide: true,
					cellRenderer: (data) => {
						if(data.value) {
							return this.$moment.utc(data.value).format('DD-MM-YYYY HH:mm:ss')
						}
						else {
							return ''
						}
					}
				},
				],
				selectedDomains: [],
			}
		},
		watch: {
			windowWidth(val) {
				// Toggle colummn movable
				this.toggleColumnMovable(val);
				// Toggle colummn pinning
				this.toggleColumnPinned(val);
			},
		},
		computed: {
			windowWidth: function(){
				return this.$store.state.windowWidth;
			},
			paginationPageSize() {
				if(this.gridApi) {
					return this.gridApi.paginationGetPageSize()
				}
				else {
					return 500
				}
			},
			totalPages() {
				if(this.gridApi) {
					return this.gridApi.paginationGetTotalPages()
				}
				else {
					return 0
				}
			},
			currentPage: {
				get() {
					if(this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1
						else return 1
					},
				set(val) {
					this.gridApi.paginationGoToPage(val - 1);
				}
			},
		},
		methods: {
			toggleColumnMovable(val) {
				if(val <= 576) {
					this.defaultColDef['suppressMovable'] = true;
				}
				else {
					this.defaultColDef['suppressMovable'] = false;
				}
			},
			toggleColumnPinned(val) {
				if(val <= 576) {
					this.maxPageNumbers = 4;
					this.gridOptions.columnApi.setColumnPinned('domain', null);
				}
				else {
					this.gridOptions.columnApi.setColumnPinned('domain', 'left')
				}
			},
			onColumnResized() {
				this.gridApi.resetRowHeights();
			},
			updateSearchQuery(val) {
				this.gridApi.setQuickFilter(val);
			},
			sizeColumnsToFit() {
				this.gridApi.sizeColumnsToFit();
			},
			onRowSelected(event) {
				if(event.node.selected) {
					// Push to selectedDomains
					this.pushToArray(this.selectedDomains, event.node.data, 'domain_id');
				}
				else {
					// Remove from selectedDomains
					this.removeFromArray(this.selectedDomains, event.node.data, 'domain_id');
				}
			},
			takedownMultiple() {
				// Save takedown domains
				this.$store.dispatch("setTakedownDomains", this.selectedDomains.map(x => {
					return {
						domain: x['domain'],
						domain_id: x['domain_id'],
						tld: x['tld']
					};
				}));
				// Redirect to listing
				this.$router.push({ path: '/suspension' })
			},
			unsuspendMultiple() {
				// Save takedown domains
				this.$store.dispatch("setUnsuspensionDomains", this.selectedDomains.map(x => {
					return {
						domain: x['domain'],
						domain_id: x['domain_id'],
						tld: x['tld']
					};
				}));
				// Redirect to listing
				this.$router.push({ path: '/unsuspension' })
			},
			getContextMenuItems(params) {
				let result,
					self = this;

				if(params['column'] && params['column']['colId'] && params['column']['colId'] == 'domain') {
					result = [
						{
							name: "View Domain Details",
							action: function() {
								let routeData = self.$router.resolve({name: 'search', query: {domain_name: params.value}});
								window.open(routeData.href, '_blank');
							},
						},
						'separator',
						{
							name: "Resolve Domain(s)",
							action: function() {
								let rangeSelection = self.gridApi.getCellRanges(),
									value = params['value'];

								if(rangeSelection) {
									// Get selected cells & iterate through them to find domains
									rangeSelection.forEach(item => {
										let start = Math.min(item.startRow.rowIndex, item.endRow.rowIndex),
											end = Math.max(item.startRow.rowIndex, item.endRow.rowIndex),
											isRightClicked = false;
										
										for (let i = start; i <= end; i++) {
											if(self.gridApi.getDisplayedRowAtIndex(i)['data']['domain'] == value) {
												isRightClicked = true
											}
											window.open('https://anonymto.com/?http://' + self.gridApi.getDisplayedRowAtIndex(i)['data']['domain'], '_blank');
										}
										
										if(!isRightClicked) {
											window.open('https://anonymto.com/?http://' + value);
										}
									});
								}
								else {
									window.open('https://anonymto.com/?http://' + value);
								}
							},
						},
						'separator',
						'copy',
						'copyWithHeaders',
						'paste',
						'separator',
						'export'
					];
				}
				else {
					result = [
						'copy',
						'copyWithHeaders',
						'paste',
						'separator',
						'export'
					];
				}

				return result;
			}
		},
		beforeMount() {
			// Reset movable columns on mobile
			this.toggleColumnMovable(this.windowWidth);

			// Set Grid statusbar
			this.statusBar = {
				statusPanels: [
				{
					statusPanel: "agTotalRowCountComponent",
					align: "left"
				},
				{
					statusPanel: "agFilteredRowCountComponent",
					align: "right"
				}
				]
			};

			// Set Grid sidebar
			this.sideBar = {
				toolPanels: [
				{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel",
					toolPanelParams: {
						suppressRowGroups: true,
						suppressValues: true,
						suppressPivots: true,
						suppressPivotMode: true,
						suppressSideButtons: true,
						suppressColumnSelectAll: true,
						suppressColumnExpandAll: true
					}
				}
				],
			};
		},
		mounted() {
			this.gridApi = this.gridOptions.api;

			// Resize to fit columns
			this.sizeColumnsToFit();

			// Reset pinned columns on mobile
			this.toggleColumnPinned(this.windowWidth);

		}
	}

</script>