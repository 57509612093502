<template>
	<div>
		<vx-card class="mb-base">
			<div class="vx-row">
				<div class="vx-col sm:w-1/2 lg:w-1/3 w-full mb-2">
					<vs-input class="w-full" label="Keyword" v-model="dnsSearch['keyword']" @keyup.enter="search()" />
				</div>
				<div class="vx-col sm:w-1/2 lg:w-1/3 w-full mb-2">
					<vs-input class="w-full" label="Domain" v-model="dnsSearch['domain']"  @keyup.enter="search()" />
				</div>
				<div class="vx-col sm:w-1/2 lg:w-1/3 w-full mb-2">
					<label class="vs-input--label">Watchlist</label>
					<v-select class="w-full" v-model="watchlist['value']" :options="watchlist['options']">
						<template v-slot:option="option">
							<template v-if="option.abuse > 50">
							{{ option.label }} - <span class="text-danger">{{ option.abuse }}%</span>
							</template>
							<template v-else-if="option.abuse  > 4">
							{{ option.label }} - <span class="text-warning">{{ option.abuse }}%</span>
							</template>
							<template v-else>
							{{ option.label }} - <span class="text-success">{{ option.abuse }}%</span>
							</template>
						</template>
					</v-select>
				</div>
			</div>
			<div class="vx-row">
				<div class="vx-col w-full mt-4">
					<vs-button ref="loadableButtonSearch" id="button-with-loading-search"  class="mb-2 mr-4" @click="search()">Search</vs-button>
					<vs-button class="mb-2" color="danger" type="border" @click="resetDetails()">Reset</vs-button>
				</div>
			</div>
		</vx-card>

		<!-- No Results Alert -->
		<div v-if="searchFlags['searched'] && searchFlags['noResults']" class="vx-col w-full mb-base">
			<vs-alert active="true" icon="new_releases">No domains found for the above search criteria, please change the search criteria & try again.</vs-alert>
		</div>

		<!-- Results Count Alert -->
		<template v-if="searchFlags['searched'] && !searchFlags['noResults'] && searchTerm">
			<vs-alert v-if="searchFlags['watchlist']" active="true" class="mb-base vs-con-loading__container"><div class="flex justify-between items-center"><p><strong>Watchlist Entry</strong>: {{ searchTerm }} - {{ searchCount }} results</p> <vs-button id="button-with-loading-watchlist" class="ml-4" type="border" @click="saveWatchlistPrompt('remove')" size="small" :disabled="watchlistAction">Remove from Watchlist</vs-button></div></vs-alert>
			<vs-alert v-else active="true" class="mb-base vs-con-loading__container"><div class="flex justify-between items-center"><p><strong>Search Term</strong>: {{ searchTerm }} - {{ searchCount }} results</p> <vs-button id="button-with-loading-watchlist" class="ml-4" type="border" @click="saveWatchlistPrompt('add')" size="small" :disabled="watchlistAction">Add to Watchlist</vs-button></div></vs-alert>
		</template>

		<!-- Domain Details -->
		<template v-if="searchFlags['searched'] && !searchFlags['noResults']">
			<vx-card class="overflow-hidden mb-base">
				<div slot="no-body">
					<div class="vs-tabs--custom con-vs-tabs vs-tabs vs-tabs-primary vs-tabs-position-top">
						<div class="con-ul-tabs">
							<ul class="ul-tabs vs-tabs--ul ul-tabs-fixed">
								<li class="vs-tabs--li" :class="{'activeChild' : searchView == 'registered'}">
									<button type="button" class="vs-tabs--btn" @click="toggleSearchView('registered')">Registered<span class="hidden md:inline"> Domains</span></button>
								</li>
								<li class="vs-tabs--li" :class="{'activeChild' : searchView == 'available'}">
									<button type="button" class="vs-tabs--btn" @click="toggleSearchView('available')">Available<span class="hidden md:inline"> Domains</span></button>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</vx-card>

			<template v-if="searchFlags['watchlist']">
				<!-- Watchlist Results -->
				<DNSTwisterWatchlist v-if="searchView == 'registered'" :user-role="user_role" :table-data="watchlistDetails['registered']" :stats-data="watchlistDetails['stats']" :abuse-reported="watchlistDetails['abuseReported']" :unsuspension="watchlistDetails['unsuspension']" :registry-lock="watchlistDetails['registryLock']" />
				<DNSTwisterWatchlistAvailable v-if="searchView == 'available'" :user-role="user_role" :table-data="watchlistDetails['available']" />
			</template>
			<template v-else>
				<!-- Search Results -->
				<DNSTwisterSearchResults v-if="searchView == 'registered'" :user-role="user_role" :table-data="domainDetails['registered']" :stats-data="domainDetails['stats']" :abuse-reported="domainDetails['abuseReported']" :unsuspension="domainDetails['unsuspension']" :registry-lock="domainDetails['registryLock']" />
				<DNSTwisterSearchResultsAvailable v-if="searchView == 'available'" :user-role="user_role" :table-data="domainDetails['available']" />
			</template>
		</template>
	</div>
</template>

<script>
	import vSelect from 'vue-select'
	import DNSTwisterSearchResults from '@/components/ag-grid-tables/DNSTwisterSearchResults.vue'
	import DNSTwisterSearchResultsAvailable from '@/components/ag-grid-tables/DNSTwisterSearchResultsAvailable.vue'
	import DNSTwisterWatchlist from '@/components/ag-grid-tables/DNSTwisterWatchlist.vue'
	import DNSTwisterWatchlistAvailable from '@/components/ag-grid-tables/DNSTwisterWatchlistAvailable.vue'

	export default {
		components: {
			'v-select': vSelect,
			DNSTwisterSearchResults,
			DNSTwisterSearchResultsAvailable,
			DNSTwisterWatchlist,
			DNSTwisterWatchlistAvailable,
		},
		data() {
			return {
				searchView: 'registered',
				searchCount: 0,
				searchTerm: '',
				dnsSearch: {
					keyword: '',
					domain: '',
				},
				watchlist: {
					value: null,
					options: []
				},
				domainDetails: {
					stats: {
						totalDomains: 0,
						totalServerHold: 0,
						totalActive: 0,
						totalAbuse: 0,
						abuseRate: 0,
					},
					registered: [],
					abuseReported: [],
					unsuspension: [],
					registryLock: [],
					available: []
				},
				watchlistDetails: {
					stats: {
						totalDomains: 0,
						totalServerHold: 0,
						totalActive: 0,
						totalAbuse: 0,
						abuseRate: 0,
					},
					registered: [],
					abuseReported: [],
					unsuspension: [],
					registryLock: [],
					available: []
				},
				searchFlags: {
					watchlist: false,
					watchlistAction: false,
					searched: false,
					noResults: false,
				},
			}
		},
		watch: {
			
		},
		computed: {
			windowWidth: function(){
				return this.$store.state.windowWidth;
			},
			user_role() {
				return JSON.parse(localStorage.getItem('userDetails')).role;
			},
		},
		methods: {
			showButtonLoading(){
				this.$vs.loading({
					background: 'primary',
					color: '#FFFFFF',
					container: '#button-with-loading-search',
					scale: 0.45
				});
			},
			hideButtonLoading(){
				this.$vs.loading.close("#button-with-loading-search > .con-vs-loading");
			},
			showWatchlistLoading(){
				this.$vs.loading({
					background: 'primary',
					color: '#FFFFFF',
					container: '#button-with-loading-watchlist',
					scale: 0.45
				});
			},
			hideWatchlistLoading(){
				this.$vs.loading.close("#button-with-loading-watchlist > .con-vs-loading");
			},
			toggleSearchView(type) {
				this.searchView = type;
			},
			resetDetails() {
				this.dnsSearch = {
					keyword: '',
					domain: '',
				};
				this.searchFlags = {
					watchlist: false,
					searched: false,
					noResults: false,
				};
				this.searchCount = 0;
				this.domainDetails = {
					stats: {
						totalDomains: 0,
						totalServerHold: 0,
						totalActive: 0,
						totalAbuse: 0,
						abuseRate: 0,
					},
					registered: [],
					abuseReported: [],
					unsuspension: [],
					registryLock: [],
					available: []
				};
				this.watchlistDetails = {
					stats: {
						totalDomains: 0,
						totalServerHold: 0,
						totalActive: 0,
						totalAbuse: 0,
						abuseRate: 0,
					},
					registered: [],
					abuseReported: [],
					unsuspension: [],
					registryLock: [],
					available: []
				}

				this.$router.replace('/dns-twister');
			},
			search() {
				let self = this;

				if (this.watchlist['value'] != null) {
					// Show loader
					this.showButtonLoading();

					// Enable watchlist action button
					this.watchlistAction = false;

					// Reset search term
					this.searchTerm = '';

					this.$axiosSecure.get('/get-dns-watch-list-report', {
						id: this.watchlist['value']['value']
					})
					.then((response) => {
						// Store search term
						this.searchTerm = this.watchlist['value']['label'];

						if (response.data.message !== undefined) {
							// Search has been queued
							if (response.data.message === 'request queued') {
								this.$vs.dialog({
									color: 'primary',
									title: 'Request Queued',
									text: 'Your search request has been queued, a flock alert will be sent upon completion.',
									accept: this.resetDetails
								});
							}
							else if (response.data.message === 'request queued') {
								this.$vs.dialog({
									color: 'primary',
									title: 'Processing Request',
									text: 'Your search request is being processed, a flock alert will be sent upon completion.',
									accept: this.resetDetails
								});
							}
							else {
								this.resetDetails();
							}
						}
						else {
							// Set Searched Flag
							this.searchFlags['searched'] = true;
							// Reset Watchlist Flag
							this.searchFlags['watchlist'] = true;
							// Reset noResults Flag
							this.searchFlags['noResults'] = false;
							// Reset Count
							this.searchCount = 0;

							// Assign Registered Tab Data
							if (response.data.registered !== undefined) {
								this.watchlistDetails['stats'] = response.data.registered['stats'];
								this.watchlistDetails['registered'] = response.data.registered['newRegistrations'];
								this.watchlistDetails['abuseReported'] = response.data.registered['abuseReported'];
								this.watchlistDetails['unsuspension'] = response.data.registered['unsuspension'];
								this.watchlistDetails['registryLock'] = response.data.registered['registryLock'];
								// Store count
								this.searchCount = this.searchCount + response.data.registered['newRegistrations'].length;
							}
							else {
								this.watchlistDetails['stats'] = {
									totalDomains: 0,
									totalServerHold: 0,
									totalActive: 0,
									totalAbuse: 0,
									abuseRate: 0,
								};
								this.watchlistDetails['registered'] = [];
								this.watchlistDetails['abuseReported'] = [];
								this.watchlistDetails['unsuspension'] = [];
								this.watchlistDetails['registryLock'] = [];
							}

							// Assign Available Tab Data
							if (response.data.available !== undefined) {
								this.watchlistDetails['available'] = response.data.available;
								// Store count
								this.searchCount = this.searchCount + response.data.available.length;
							}
							else {
								this.watchlistDetails['available'] = [];
							}
						}
						// Hide loader
						this.hideButtonLoading();
					})
					.catch((error) => {
						// Hide loader
						this.hideButtonLoading();
						// Error notification
						self.$vs.notify({
							color: 'danger',
							title: 'Something went wrong',
							text: 'Please contact the server admin'
						});
	
						console.log(error);

						// Reset Watchlist Flag
						this.searchFlags['watchlist'] = false;
					});
				}
				else if (this.dnsSearch['keyword'] != '' || this.dnsSearch['domain'] != '') {
					// Show loader
					this.showButtonLoading();

					// Enable watchlist action button
					this.watchlistAction = false;
					
					let payload = {}

					if (this.dnsSearch['domain']) {
						payload['domain'] = this.dnsSearch['domain'];
					}
					if (this.dnsSearch['keyword']) {
						payload['keyword'] = this.dnsSearch['keyword'];
					}

					// Reset search term
					this.searchTerm = '';

					this.$axiosSecure.get('/get-dns-report', {
						params: payload
					})
					.then((response) => {
						// Store search term
						this.searchTerm = this.dnsSearch['keyword'] != '' ? this.dnsSearch['keyword'] : this.dnsSearch['domain'];

						if (response.data.message !== undefined) {
							// Search has been queued
							if (response.data.message === 'request queued') {
								this.$vs.dialog({
									color: 'primary',
									title: 'Request Queued',
									text: 'Your search request has been queued, a flock alert will be sent upon completion.',
									accept: this.resetDetails
								});
							}
							else if (response.data.message === 'request queued') {
								this.$vs.dialog({
									color: 'primary',
									title: 'Processing Request',
									text: 'Your search request is being processed, a flock alert will be sent upon completion.',
									accept: this.resetDetails
								});
							}
							else {
								this.resetDetails();
							}
						}
						else {
							// Set Searched Flag
							this.searchFlags['searched'] = true;
							// Reset Watchlist Flag
							this.searchFlags['watchlist'] = false;
							// Reset noResults Flag
							this.searchFlags['noResults'] = false;
							// Reset Count
							this.searchCount = 0;

							// Assign Registered Tab Data
							if (response.data.registered !== undefined) {
								this.domainDetails['stats'] = response.data.registered['stats'];
								this.domainDetails['registered'] = response.data.registered['newRegistrations'];
								this.domainDetails['abuseReported'] = response.data.registered['abuseReported'];
								this.domainDetails['unsuspension'] = response.data.registered['unsuspension'];
								this.domainDetails['registryLock'] = response.data.registered['registryLock'];
								// Store count
								this.searchCount = this.searchCount + response.data.registered['newRegistrations'].length;
							}
							else {
								this.domainDetails['stats'] = {
									totalDomains: 0,
									totalServerHold: 0,
									totalActive: 0,
									totalAbuse: 0,
									abuseRate: 0,
								};
								this.domainDetails['registered'] = [];
								this.domainDetails['abuseReported'] = [];
								this.domainDetails['unsuspension'] = [];
								this.domainDetails['registryLock'] = [];
							}

							// Assign Available Tab Data
							if (response.data.available !== undefined) {
								this.domainDetails['available'] = response.data.available;
								// Store count
								this.searchCount = this.searchCount + response.data.available.length;
							}
							else {
								this.domainDetails['available'] = [];
							}
						}
						// Hide loader
						this.hideButtonLoading();
					})
					.catch((error) => {
						// Hide loader
						this.hideButtonLoading();
						// Error notification
						self.$vs.notify({
							color: 'danger',
							title: 'Something went wrong',
							text: 'Please contact the server admin'
						});

						console.log(error);
						
						// Reset Searched Flag
						this.searchFlags['searched'] = false;
					});
				}
			},
			populateWatchlistData() {
				// Get Watchlist JSON
				this.$axiosSecure.get('/get-dns-watch-lists')
				.then((response) => {
					this.watchlist['options'] = response.data.map((x) => {
						return {
							label: x['watch_list_name'],
							value: x['id'],
							abuse: x['abuse_rate']
						}
					});
				})
				.catch((error) => {
					// Error notification
					this.$vs.notify({
						color: 'danger',
						title: 'Something went wrong',
						text: 'Please contact the server admin'
					});

					console.log(error);
				});
			},
			saveWatchlistPrompt(flag) {
				if (flag == 'add') {
					// Open confirm popup
					this.$vs.dialog({
						type: 'confirm',
						color: 'primary',
						title: 'Confirm',
						text: 'Add the selected keyword/domain to watchlist?',
						accept: this.acceptWatchlistAdd
					});
				}
				else {
					// Open confirm popup
					this.$vs.dialog({
						type: 'confirm',
						color: 'danger',
						title: 'Confirm',
						text: 'Remove the selected keyword/domain from watchlist?',
						accept: this.acceptWatchlistDelete
					});
				}
			},
			acceptWatchlistAdd() {
				let payload = {
					value: this.searchTerm,
					watchtower_email: JSON.parse(localStorage.getItem('userDetails')).email
				}

				if (this.dnsSearch['domain']) {
					payload['type'] = 'domain';
				}
				if (this.dnsSearch['keyword']) {
					payload['type'] = 'keyword';
				}

				// Show loader
				this.showWatchlistLoading();

				// Save Watchlist Axios Call
				this.$axiosSecure.post('/save-dns-watch-list', payload)
				.then((success) => {
					if(success.data == "created") {
						// Confimation notification
						this.$vs.notify({
							color: 'success',
							title: 'Watchlist save success',
							text: 'The search term has been saved to watchlist'
						});

						// Get Watchlist Options
						this.populateWatchlistData();

						// Disable action button
						this.watchlistAction = true;
						
						// Hide loader
						this.hideWatchlistLoading();
					}
					else if(success.data == "duplicate") {
						// Confimation notification
						this.$vs.notify({
							color: 'warning',
							title: 'Watchlist save error',
							text: 'The watchlist already exists'
						});

						// Get Watchlist Options
						this.populateWatchlistData();
						
						// Hide loader
						this.hideWatchlistLoading();
					}
				})
				.catch((error) => {
					// Hide loader
					this.hideWatchlistLoading();
					// Error notification
					this.$vs.notify({
						color: 'danger',
						title: 'Something went wrong',
						text: 'Please contact the server admin'
					});

					console.log(error);
				});
			},
			acceptWatchlistDelete() {
				// Show loader
				this.showWatchlistLoading();

				// Save Watchlist Axios Call
				this.$axiosSecure.post('/delete-dns-watch-list', {
					id: this.watchlist['value']['value'],
					watchtower_email: JSON.parse(localStorage.getItem('userDetails')).email
				})
				.then(() => {
					// Confimation notification
					this.$vs.notify({
						color: 'success',
						title: 'Watchlist delete success',
						text: 'The search term has been removed from watchlist'
					});

					// Get Watchlist Options
					this.populateWatchlistData();

					// Disable action button
					this.watchlistAction = true;
					
					// Hide loader
					this.hideWatchlistLoading();
				})
				.catch((error) => {
					// Hide loader
					this.hideWatchlistLoading();
					// Error notification
					this.$vs.notify({
						color: 'danger',
						title: 'Something went wrong',
						text: 'Please contact the server admin'
					});

					console.log(error);
				});
			}
		},
		beforeMount() {
			
		},
		mounted() {
			// Get Watchlist Options
			this.populateWatchlistData();
		}
	}
</script>
